<template>
  <a-card
    hoverable
    class="preview"
  >
    <img
      v-if="state.imageUrl.length > 0"
      :src="state.imageUrl"
      alt="Preview"
      class="preview__image"
    />

    <a-list
      :data-source="data"
      item-layout="horizontal"
    >
      <template
        #renderItem="{ item }"
      >
        <a-list-item>
          <a-list-item-meta
            :description="item.text"
          >
            <template
              #title
            >
              {{ item.title }}
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>

    <div
      class="card__buttons"
    >
      <a-button
        @click="handleEditButton"
      >
        Редактировать
      </a-button>

      <a-button
        type="primary"
        @click="handleApplyButton"
      >
        Отправить
      </a-button>
    </div>

    <a-modal
      v-model:visible="state.showModal"
      :centered="true"
      :closable="false"
      title="Цель-челлендж успешно создана!"
    >
      <p>
        Поздравляем, твой целленж на одобрении, после одобрения ты сможешь приступить к действиям.
        Обычно это происходит быстро, но иногда эльфы поддержки засыпают до утра!
      </p>
      <p>
        Одобрение прилетит в бот
      </p>

      <template
        #footer
      >
        <a-button
          key="submit"
          type="primary"
          @click="handleModalOk"
        >
          Перейти в бот
        </a-button>
      </template>
    </a-modal>
  </a-card>
</template>

<script
  lang="ts"
>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
} from 'vue';
import {
  useRouter,
} from 'vue-router';

import {
  notification,
} from 'ant-design-vue';

import LocalStorageKeysEnum from '@/app/Services/LocalStorage/Enum/LocalStorageKeysEnum';

import ServiceContainerInterface from '@/app/Services/ServiceContainer/Contract/ServiceContainerInterface';
import UserInfoResponse from '@/shared/Api/Model/UserInfoApi/UserInfoResponse';
import InvitationCodeModel from '@/shared/Api/Model/RPC/InvitationCode/InvitationCodeModel';

export default defineComponent({
  name: 'ChallengeConfirmPage',
  setup() {
    const serviceContainer = inject<ServiceContainerInterface>('serviceContainer');

    if (!serviceContainer) {
      throw new Error('serviceContainer not injected');
    }

    const {
      store,
      localStorageService,
      apiService,
      challengeService,
    } = serviceContainer;

    const router = useRouter();

    const state = reactive<{
      values: {
        description: string | null;
        reason: string | null;
        bet: string | null;
        reportType: string | null;
        numberOfReports: string | null;
        userName: string | null;
      };
      imageUrl: string;
      showModal: boolean;
    }>({
      values: {
        description: null,
        reason: null,
        bet: null,
        reportType: null,
        numberOfReports: null,
        userName: null,
      },
      imageUrl: '',
      showModal: false,
    });

    const userInfo = computed<UserInfoResponse>(
      () => store.getters.getUser,
    );

    const data = computed(() => ([
      {
        title: 'Ваше имя и фамилия:',
        text: state.values.userName,
      },
      {
        title: 'Ваша цель:',
        text: state.values.description,
      },
      {
        title: 'Ваша мотивация:',
        text: state.values.reason,
      },
      {
        title: 'Количество действий для достижения цели:',
        text: state.values.numberOfReports,
      },
      {
        title: 'Формат отчёта:',
        text: state.values.reportType,
      },
      {
        title: 'Ваша ставка:',
        text: state.values.bet,
      },
    ]));

    onMounted(async () => {
      const savedValues = localStorageService.read<{
        description: string | null;
        reason: string | null;
        bet: string | null;
        reportType: string | null;
        numberOfReports: string | null;
        userName: string | null;
        type?: 'CHALLENGE' | 'ONE_DAY_CHALLENGE' | null;
      }>(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA);

      if (savedValues) {
        state.values = savedValues;

        const {
          userName,
          description,
          reason,
          bet,
          reportType,
          numberOfReports,
          type,
        } = savedValues;

        const blob = await apiService.challengesApi.createImage({
          user: {
            fullName: userName ?? '',
          },
          challenge: {
            description: description ?? '',
            reason: reason ?? '',
            bet: parseInt(bet ?? '0', 10),
            reportType: reportType ?? '',
            numberOfReports: parseInt(numberOfReports ?? '0', 10),
            type: type ?? undefined,
          },
        });

        const urlCreator = window.URL || window.webkitURL;
        state.imageUrl = urlCreator.createObjectURL(blob);
      }
    });

    function handleEditButton() {
      const savedValues = localStorageService.read<{
        description: string | null;
        reason: string | null;
        bet: string | null;
        reportType: string | null;
        numberOfReports: string | null;
        userName: string | null;
        type?: string | null;
      }>(LocalStorageKeysEnum.CHALLENGE_SAVE_DATA);

      if (userInfo.value && savedValues?.type === 'ONE_DAY_CHALLENGE') {
        return router.push({ name: 'one-day-challenge' });
      }

      if (userInfo.value) {
        return router.push({ name: 'challenge' });
      }

      return router.push({ name: 'start' });
    }

    async function handleApplyButton() {
      if (!serviceContainer) {
        throw new Error('serviceContainer not injected');
      }

      const isNewUserFromBot = userInfo.value
        && userInfo.value.isRegisteredFromBot
        && userInfo.value.challenges.length === 0
        && userInfo.value.clubs.length === 0
        && userInfo.value.tournaments.length === 0;

      if (userInfo.value) {
        try {
          await challengeService.create();

          if (isNewUserFromBot) {
            const invitationCode = serviceContainer.localStorageService
              .read<InvitationCodeModel>(LocalStorageKeysEnum.INVITATION_CODE);

            if (invitationCode?.club) {
              await serviceContainer?.apiService.rpc.club.addToUser.call(invitationCode.club);

              serviceContainer?.localStorageService.remove(LocalStorageKeysEnum.INVITATION_CODE);
            }

            state.showModal = true;
          } else {
            notification.success({
              message: 'Цель-челлендж успешно создана!',
              description: 'Твоя Цель-челлендж после одобрения будет опубликована в чате для отчётов и можно будет приступать к ее выполнению!',
              duration: 30,
            });
          }
        } catch {
          notification.error({
            message: 'Что-то пошло не так!',
            description: 'Твоя Цель-челлендж не была создана! Повторите попытку позже.',
            duration: 30,
          });
        }
      }

      if (isNewUserFromBot) {
        return;
      }

      router.push({ name: 'home' });
    }

    function handleModalOk() {
      state.showModal = false;
      // @TODO получать ссылку на бота из админки
      window.location.href = 'http://t.me/YouFC_Club_bot';
    }

    return {
      state,
      data,
      handleEditButton,
      handleApplyButton,
      handleModalOk,
    };
  },
});
</script>

<style
  lang="scss"
  scoped
>
.preview {
  max-width: 300px;
  margin: 0 auto;

  &__image {
    max-width: 100%;
  }
}
</style>
